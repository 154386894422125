import { Link } from '@remix-run/react'
import type { Employee, Profile } from '@repo/db/schema'
import { Badge } from '@repo/ui/components/Badge.js'
import type { ComponentProps } from 'react'
import { UserAvatar } from './UserAvatar'

export const EmployeeBadge = ({
  employeeId,
  displayName,
  avatarUrl,
  ...props
}: {
  displayName: Profile['displayName']
  employeeId: Employee['id']
  avatarUrl: Profile['avatarUrl']
} & ComponentProps<typeof Badge>) => {
  return (
    <Link to={`/team/${employeeId}`}>
      <Badge className="gap-1 font-medium hover:underline" variant="violet" {...props}>
        <UserAvatar src={avatarUrl} className="size-4" />
        <span className="max-w-48 truncate">{displayName}</span>
      </Badge>
    </Link>
  )
}
